import React from 'react'
import '../../css/Navbar.css';

import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

function Combo() {
  const [t,i18n] = useTranslation("global");
    const handleChangeLanguage = (lang) => {
      i18n.changeLanguage(lang);
    };
  
  return (
    <div>
        <select className='combo' onChange={(e) => handleChangeLanguage(e.target.value)}>*
            {/* <option value="">Language</option> */}
            <option value="english">En</option>
            <option value="french">Fr</option>
        </select>
    </div>
  )
}

export default Combo
