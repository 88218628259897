import React from 'react';
import '../css/BigDividedCard.css';
import BigDividedCardText from './BigDividedCardText';
import { useTranslation } from "react-i18next";


function BigDividedCard2() {
  const [t] = useTranslation("global");

  return (
    <div>
    <div className='big__dividedcard__container'>
      <img alt='' src="./images/clica.jpg"/>
      <div className='big__dividedcard__text'>
          <BigDividedCardText 
              titleBDC={t("Blog.blog3.titleBDC1")}
              descBDC={t("Blog.blog3.descBDC1")}
              />
          <BigDividedCardText 
              titleBDC={t("Blog.blog3.titleBDC2")}
              descBDC={t("Blog.blog3.descBDC2")}
          />
          <BigDividedCardText 
              titleBDC={t("Blog.blog3.titleBDC3")}
              
          />
          <BigDividedCardText 
              titleBDC={t("Blog.blog3.titleBDC4")}
              descBDC={t("Blog.blog3.descBDC4")}
          />
          <BigDividedCardText 
              titleBDC={t("Blog.blog3.titleBDC5")}
              descBDC={t("Blog.blog3.descBDC5")}
          />
          <BigDividedCardText 
              titleBDC={t("Blog.blog3.titleBDC6")}
              descBDC={t("Blog.blog3.descBDC6")}
          />
      </div>
    </div>
  </div>
  )
}

export default BigDividedCard2
