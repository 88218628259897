import React from 'react';
import '../css/IconSection.css'
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function IconSection() {
    const [t] = useTranslation("global");
  return (
    <div className='container__icons'>
      <div className="Section__icons__list">
                <div className='Section__icons__container'>
                    <div className="Section__single__infos" data-aos="flip-left">
                        <div  className="Section__infos__icon"> 
                            <i  className="fas fa-bolt green-clr-bg" />
                        </div>
                        <div  className="Section__text__infos">
                            <Link className='Link_divided__card'> <h5 className="Section__title__infos">{t("Solution.IconSection.Link_divided__card1")}</h5></Link>
                        </div>
                    </div>
                    <div className="Section__single__infos" data-aos="flip-left">
                        <div  className="Section__infos__icon"> 
                            <i  className="fas fa-leaf green-clr-bg" />
                        </div>
                        
                        <div  className="Section__text__infos">
                            <Link className='Link_divided__card'> <h5 className="Section__title__infos">{t("Solution.IconSection.Link_divided__card2")}</h5></Link>
                        </div>
                    </div>
                    <div className="Section__single__infos" data-aos="flip-left">
                        <div  className="Section__infos__icon"> 
                            <i  className="fas fa-chart-line green-clr-bg" />
                        </div>
                        <div className="Section__text__infos">
                            <Link className='Link_divided__card'> <h5 className="Section__title__infos">{t("Solution.IconSection.Link_divided__card3")}</h5></Link>
                        </div>
                    </div>
                    <div className="Section__single__infos" data-aos="flip-left">
                        <div  className="Section__infos__icon"> 
                            <i  className="fas fa-tree green-clr-bg" />
                        </div>
                        <div className="Section__text__infos">
                            <Link className='Link_divided__card'> <h5 className="Section__title__infos">{t("Solution.IconSection.Link_divided__card4")}</h5></Link>
                        </div>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default IconSection
