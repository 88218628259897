import React from 'react';
import '../css/SmallDividedCard.css'

function SmallDividedCard(props) {
  return (
    <div>
    <div className='small__container__dc'>
      <img alt='' src={props.smalldcimg} />
      <div className='small__dc__text'>
        <h1>{props.smalldctitle} </h1>
        <p>{props.smalldcdesc} </p>
      </div>
    </div>
    
    </div>
  )
}

export default SmallDividedCard
