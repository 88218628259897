import React from 'react';
import '../css/DividedCards.css'
import { Link } from 'react-router-dom'
import DividedCardItem from '../Mini-components/js/DividedCardItem';


function DividedCards(props) {
    
  return (
    <div className='divided__card__Cont'>
      <>
        <Link  to='/Blog1' className='Link_divided__card'>
            <DividedCardItem 
                aosDCI="fade-left"
                srcDCI={props.divcardim1}
                titleDCI={props.dctitle1} 
                descDCI={props.dcdesc1}
            />
        </Link>
        <Link to='/Blog2' className='Link_divided__card'>
            <DividedCardItem
                aosDCI="zoom-in"
                srcDCI={props.divcardim2}
                titleDCI={props.dctitle2} 
                descDCI={props.dcdesc2}
            />
        </Link>
        <Link to='/Blog3' className='Link_divided__card'>
            <DividedCardItem 
                aosDCI="fade-right"
                srcDCI={props.divcardim3}
                titleDCI={props.dctitle3} 
                descDCI={props.dcdesc3}
            />
        </Link>
      </>
    </div>
  )
}

export default DividedCards
