import React from 'react'
import Navbar from '../../components/js/Navbar'
import HeroSection from '../../components/js/HeroSection'
import Footer from '../../components/js/Footer'
import Cards from '../../components/js/Cards'
import { Button } from '../../components/js/Button';
import SecondeSection from '../../components/js/SecondeSection'
import SecondeSectionVideo from '../../components/js/SecondeSectionVideo'
import MoroccoFlag from '../../components/js/MoroccoFlag'
import List from '../../components/js/List'
import Faq from '../../components/js/Faq'
import { useTranslation } from "react-i18next";
import logoV from "../../assets/LogoV.png";
import hero from "../../assets/mail.png";

function AboutUs() {
  const [t] = useTranslation("global");
  const topFunction = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div>
      <Navbar/>          
      <HeroSection src={hero} herotext={t("AboutUs.HeroSection.herotext")} heroH1={t("AboutUs.HeroSection.heroH1")}/> 
      <MoroccoFlag />
      <List />
      <Cards />  
      <SecondeSection src='../images/pppp.jpg' logoV={logoV}  textP={t("SecondeSection.textP")} h1={t("SecondeSection.h1")}  /> 
      <Faq 
        splitfaqtitle="1. Comment Fonctionne la Plateforme MAHAAM ?"
        splitfaqvideo="./images/aboutus.mp4"
      />
      <SecondeSectionVideo src='../images/videtest.png' h1={t("SecondeSectionVideo.h1")}  />
      <Footer/>
      <Button onClick={topFunction} id="movetop" title="Go to top"  buttonStyle= 'btn--primary' buttonSize={'btn--ScrollUp'}><i className="fas fa-level-up-alt"></i></Button>
    </div>
  )
}

export default AboutUs
