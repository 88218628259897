import React from 'react';
import '../css/SplitSection.css'
import SplitSectionVideo from '../Mini-components/js/SplitSectionVideo'
import SplitSectionTextBlack from '../Mini-components/js/SplitSectionTextBlack';

function SplitVideoEco(props) {
    return (
        <div className='split'>
          <div className='Split__container'>
            
            <SplitSectionTextBlack
                splittitlte={props.splitbigtitle}
                splitdesc={props.splitbigdesc}
            />
            <SplitSectionVideo
                srcsplitvideo={props.splitV}
            />
          </div>
          </div>
      )
}

export default SplitVideoEco
