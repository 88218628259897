import React from 'react';
import '../css/Faq.css';
import SplitFaq from '../Mini-components/js/SplitFaq';
import SplitSectionVideo from '../Mini-components/js/SplitSectionVideo';
import { useTranslation } from "react-i18next";


function Faq(props) {
  const [t] = useTranslation("global");
    const faqQuestions = [
        t("Faq.faqItems.0.faqQuestions"),
        t("Faq.faqItems.1.faqQuestions"),
        t("Faq.faqItems.2.faqQuestions"),
        t("Faq.faqItems.3.faqQuestions"),
        t("Faq.faqItems.4.faqQuestions"),
        t("Faq.faqItems.5.faqQuestions"),
        t("Faq.faqItems.6.faqQuestions"),
      ];
    
      const faqAnswers = [
        t("Faq.faqItems.0.faqanswer"),
        t("Faq.faqItems.1.faqanswer"),
        t("Faq.faqItems.2.faqanswer"),
        t("Faq.faqItems.3.faqanswer"),
        t("Faq.faqItems.4.faqanswer"),
        t("Faq.faqItems.5.faqanswer"),
        t("Faq.faqItems.6.faqanswer"),
      ];
  return (
    <div className='faq'>
      <div className='Split__faq__container'> 
        <SplitFaq 
        faqheadertitle={t("Faq.faqTitle.title")}
        faqheaderdesc={t("Faq.faqTitle.desc")} totalItems={faqQuestions.length} questions={faqQuestions} answers={faqAnswers} />

        <SplitSectionVideo
            srcsplitvideo={props.splitfaqvideo}
        />
      </div>
      </div>
  )
}

export default Faq
