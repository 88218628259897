import React from 'react';
import '../../Pages/css/MAHAAM.css';
import Footer from '../../components/js/Footer';
import Navbar from '../../components/js/Navbar';
import { Button } from '../../components/js/Button';
import SecondeSection from '../../components/js/SecondeSection';
import SplitSectionInversed from '../../components/js/SplitSectionInversed';
import SplitSection from '../../components/js/SplitSection';
import ListSolution from '../../components/js/ListSolution';
import TextSection from '../../components/js/TextSection';
import ContactUs from '../../components/js/ContactUs';
import SplitSectionV from '../../components/js/SplitSectionV';
import SplitSectionTC from '../../components/js/SplitSectionTC';
import Slider from '../../component/Slider';
import { useTranslation } from "react-i18next";
import logoV from "../../assets/LogoV.png";


function MAHAAM() {

  const [t] = useTranslation("global");
  const topFunction = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
      <Navbar/> 
      <Slider /> 
      <div className='mahaam'>
      <SplitSectionInversed 
        splitinversed="./images/home1.png "
        inversedsplittitle={t("Mahham.parag1.title")}
        inversedsplitdesc={t("Mahham.parag1.content")}
      />
      <SplitSection 
        splitbigtitle={t("Mahham.parag2.title")}
        splitbigdesc={t("Mahham.parag2.content")}
        split="./images/data.jpg"
      />
      <SplitSectionInversed 
        splitinversed="./images/home2.png "
        inversedsplittitle={t("Mahham.parag3.title")}
        inversedsplitdesc={t("Mahham.parag3.content")}
      />
      <TextSection 
        cardstitle={t("Mahham.TextSection.cardstitle")}
        cardsdesc={t("Mahham.TextSection.cardsdesc")}
      />
    <ListSolution 
        list__solution_icon1="fa-solid fa-solar-panel green-clr-bg"
        list__solution_titre1={t("Mahham.ListSolution.list__solution_titre1")}
        list__solution_desc1={t("Mahham.ListSolution.list__solution_desc1")}
        list__solution_icon2="fa-solid fa-arrow-up-right-dots green-clr-bg"
        list__solution_titre2={t("Mahham.ListSolution.list__solution_titre2")}
        list__solution_desc2={t("Mahham.ListSolution.list__solution_desc2")}
        list__solution_icon3="fa-solid fa-gauge-simple green-clr-bg"
        list__solution_titre3={t("Mahham.ListSolution.list__solution_titre3")}
        list__solution_desc3={t("Mahham.ListSolution.list__solution_desc3")}
      />
      <SecondeSection src='../images/53.png' logoV={logoV}  textP={t("Mahham.SecondeSection.textP")}  h1={t("Mahham.SecondeSection.h1")}  /> 
      <SplitSectionV 
        splitbigtitle={t("Mahham.SplitSectionV.splitbigtitle")}
        splitbigdesc={t("Mahham.SplitSectionV.splitbigdesc")}
        splitV="./images/test.mp4" 
      />
      <div className='title__Section'>
        <div className='txt'>
          <h1>MAHAAM</h1>
        </div>
      </div>
      <SplitSectionTC 
         splitinversed="./images/pc.png"
         inversedsplittitlecolor={t("Mahham.SplitSectionTC.inversedsplittitlecolor")}
         inversedsplitdesccolor={t("Mahham.SplitSectionTC.inversedsplitdesccolor")}
      />
      <ContactUs />
      <Footer/>
      <Button onClick={topFunction} id="movetop" title="Go to top"  buttonStyle= 'btn--primary' buttonSize={'btn--ScrollUp'}><i className="fas fa-level-up-alt"></i></Button>
      </div>
    </>
  );
}

export default MAHAAM;
