import React from 'react';
import '../css/Button.css';
import { Link } from 'react-router-dom';

const STYLES = ['btn--primary','btn--outline'];
const SIZES = ['btn--medium','button--large','btn--connexion','btn--ScrollUp','btn--LearnMore','btn--Translation'];
export const Button = ({
    children,
    type, 
    onClick, 
    buttonStyle, 
    buttonSize,
    buttonPath
}) => {
    const checkButtonStyle = STYLES.includes(buttonStyle) 
    ? buttonStyle 
    : STYLES[0];

    const checkButtonSize = SIZES.includes(buttonSize)
    ? buttonSize
    :SIZES[0];

    return(
        <Link to={buttonPath}>
            <button
            to = '/Connexion'
            className={`btn ${checkButtonStyle} ${checkButtonSize}`}
            onClick={onClick}
            type={type}
            >
                {children}
            </button>
        </Link>
    )
};