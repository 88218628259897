import React from 'react';
import '../css/BigDividedCard.css';
import BigDividedCardText from './BigDividedCardText';
import { useTranslation } from "react-i18next";


function BigDividedCard2() {
    const [t] = useTranslation("global");
  return (
    <div>
    <div className='big__dividedcard__container'>
      <img alt='' src="./images/134.png"/>
      <div className='big__dividedcard__text'>
          <BigDividedCardText 
                titleBDC={t("Blog.blog2.titleBDC1")}
                descBDC={t("Blog.blog2.descBDC1")}
              />
          <BigDividedCardText 
              titleBDC={t("Blog.blog2.titleBDC2")}
              descBDC={t("Blog.blog2.descBDC2")}
          />
          <BigDividedCardText 
              titleBDC={t("Blog.blog2.titleBDC3")}
              descBDC={t("Blog.blog2.descBDC3")}
          />
          <BigDividedCardText 
              titleBDC={t("Blog.blog2.titleBDC4")}
              descBDC={t("Blog.blog2.descBDC4")}
          />
          <BigDividedCardText 
              titleBDC={t("Blog.blog2.titleBDC5")}
              descBDC={t("Blog.blog2.descBDC5")}
          />
          <BigDividedCardText 
              titleBDC={t("Blog.blog2.titleBDC6")}
              descBDC={t("Blog.blog2.descBDC6")}
          />
      </div>
    </div>
  </div>
  )
}

export default BigDividedCard2
