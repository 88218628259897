import React , { useEffect } from 'react';
import '../css/Cards.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import CardItemActualite from '../Mini-components/js/CardItemActualite';

function CardsActualite() {
    useEffect(() => {
        Aos.init({ duration:3000});
      },[]);
     
  return (
    <div className='cards'>
      <div className="cards__container__main">
        <div className='cards__wrapper'>
            <div data-aos="fade-down" data-aos-delay="800" >
                <CardItemActualite  
                    backimg='../images/ww1.jpg'
                />
            </div>
            <div data-aos="zoom-out" data-aos-delay="1000" >
                 <CardItemActualite           
                    backimg='../images/ee1.jpg'
                />
            </div>
            <div data-aos="fade-up" data-aos-delay="800" >
                 <CardItemActualite 
                    backimg='../images/qq.jpg'
                />
            </div>
        </div>
      </div>
    </div>
  )
}

export default CardsActualite
