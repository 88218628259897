import React from 'react';
import '../css/BigDividedCardText.css'

function BigDividedCardText(props) {
  return (
    <div className='big__dividedcard__item__container'>
        <h1>{props.titleBDC} </h1>
        <p>{props.descBDC} </p>
    </div>
  )
}

export default BigDividedCardText
