import React, { useEffect } from 'react';
import '../css/MoroccoFlag.css'
import { Link } from 'react-router-dom'
import { Button } from './Button'
import gsap from 'gsap';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";


function MoroccoFlag() {
    const [t] = useTranslation("global");

     useEffect(() => {
    const circleEls = document.querySelectorAll('.js-circle');
    const circleTl = gsap.timeline({ repeat: -1, yoyo: true });

    let circleSize = 1;
    circleEls.forEach((circle) => {
      gsap.set(circle, { scale: circleSize });
      circleSize += 0.5;
    });

    circleTl.from(circleEls, { scale: 1, stagger: 0.175, duration: 0.6, ease: 'power0.easeNone' });
  }, []);
  useEffect(() => {
    Aos.init({ duration:3000});
  },[]);

  return (
    <div>
        <div className='container'>
            <div className='box__container'>
                <div className='box__mor' data-aos="fade-right">
                    <h3 className="box_title">{t("MoroccoFlag.box_title")}</h3>
                    <p className="box_desc" >{t("MoroccoFlag.box_desc")}</p>
                    <Link   className='our_services' >
                        <Button  buttonStyle= 'btn--primary' buttonSize={'btn--medium'}>{t("MoroccoFlag.button")} </Button>
                    </Link>
                </div>
                <div className='box__img'>
                    <div className="position-relative img-border">
                        <img data-aos="fade-left" src="../img/ma.svg" alt=""  className="carousel_img"></img>
                    </div>
                    <div className="splash__bg" data-aos="zoom-in" data-aos-delay="400">
                        <span className="js-circle"></span>
                        <span className="js-circle"></span>
                        <span className="js-circle"></span>
                        <span className="js-circle"></span>
                        <span className="js-circle"></span>
                        <span className="js-circle"></span>
                        <span className="js-circle"></span>
                        <span className="js-circle"></span>
                    </div>
                </div>
            </div>
        </div>
    
    </div>
  )
}

export default MoroccoFlag
