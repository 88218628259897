import React, { useEffect } from 'react'
import '../../Mini-components/css/SplitSectionTextBlack.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

function SplitSectionTextBlack(props) {
    useEffect(() => {
        Aos.init({ duration:3000});
      },[]);
      return (
        <div>
            <div className='Split__text_container__black'data-aos="fade-up">
                <h3 className='Split__title__black'>{props.splittitlte}</h3>
                <p className='Split__desc__black'>{props.splitdesc}</p>
            </div>
        </div>
      )
}

export default SplitSectionTextBlack
