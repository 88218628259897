import React from 'react';
import SplitTextSectionColor from '../Mini-components/js/SplitTextSectionColor'
import '../css/SplitSection.css'
import SplitSectionImgSmall from '../Mini-components/js/SplitSectionImgSmall'

function SplitSectionTC(props) {
  return (
    <div className='split'>
      <div className='Split__container'>
      <SplitSectionImgSmall 
            srcsplit={props.splitinversed}
        />
        <SplitTextSectionColor
            splittitlte={props.inversedsplittitlecolor}
            splitdesc={props.inversedsplitdesccolor}
        />
        
      </div>
    </div>
  )
}

export default SplitSectionTC
