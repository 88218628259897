import React , { useEffect } from 'react'
import '../css/Footer.css'
import { Link } from 'react-router-dom'
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

function Footer() {
    const [t] = useTranslation("global");
    useEffect(() => {
        Aos.init({ duration:1500});
      },[]);
  return (
    <div className='footer-container'>
        <div className='footer-A'>
            <div data-aos="fade-up" className='footer-subscription'>
                <Link to="/">
                    <img src="./images/MAHHAM.png" className='logo-foot'alt='' width="300px" height="60px"></img>				
                </Link>
                <div  data-aos="fade-up" className='footer-description'>
                    <p>
                        {t("Footer.footer_description")}
                    </p>
                </div> 
            </div>
            <div className='footer-links'>
                <div className='footer-link-wrapper'>
                    <div className='links-foot'>
                        <div data-aos="fade-up" className='footer-link-items'>
                            <h3>{t("Footer.QUICK_LINKS")}</h3>
                            <Link className='link' to='/AboutUs'>{t("Footer.aboutUs")}</Link>
                            <Link className='link' to='/Solution'>{t("Footer.solution")}</Link>
                            <Link className='link' to='/Actualite'>{t("Footer.news")}</Link>                
                        </div>
                        <div data-aos="fade-up" className='footer-link-items'>
                            <h3>{t("Footer.MORE_INFO")}</h3>
                            <Link className='link' to='/Actualite'>{t("Footer.news")}</Link>
                        </div>
                    </div>
                    <div data-aos="fade-up" className='footer-icons'>
                        <i class="fab fa-facebook-f"></i>
                        <i class="fab fa-twitter"></i>
                        <i class="fab fa-google-plus-g"></i>
                        <i class="fab fa-instagram"></i> 
                    </div>
                </div>
            </div>
            
        </div>
        <div  className='footer-copyright'>
            <p  className='link'>© 2024  <Link to='/' className='link'>MAHAAM</Link></p>
        </div>
    </div>

  )
}

export default Footer
