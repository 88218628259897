import React, { useState } from 'react';
import '../css/LoginSection.css';

function Contact(props) { 

  const containerStyle = {
    background: `url(${props.src}) center/cover `,
    height: '100vh',
  };

  return (
    <div className='container__login__contact' style={containerStyle} >

      <div className='hero-container__Login__contact '>
        <div className='title__login__contact'>
        <h1 className='text_login'>{props.text}</h1>
        </div>
        <form className='form__contact'>
          <label className='form__contact__label'>
            <input className='form__contact__input'
              type="text"
              name="username"
              placeholder="Enter your name"
            />
          </label>
          <label className='form__contact__label'>
            <input className='form__contact__input'
              type="text"
              name="username"
              placeholder="Enter your email"
            />
          </label>
          <label className='form__contact__label'>
            <textarea className='form__contact__input'
              type="text"
              name="username"
              placeholder="Enter your message"
            />
          </label>

          <button className='form__contact__butt' type="submit">Envoyer</button>
        </form>
      </div>
    </div>
  );
}

export default Contact;
