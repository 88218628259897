import React from 'react'
import '../css/BDCSection.css'
import SmallDividedCard from '../Mini-components/js/SmallDividedCard'
import { Link } from 'react-router-dom'
import BigDividedCard3 from '../Mini-components/js/BigDividedCard3'
import { useTranslation } from "react-i18next";


function BDCSection1() {
    const [t] = useTranslation("global");
  return (
    <div>
        <div className='BDC__Container'>
            <BigDividedCard3 />
            <div className='BDC__SMALL'>
            <Link to='/Blog1' className='Link_divided__card'>
            <SmallDividedCard 
                smalldcimg="./images/133.png"
                smalldctitle={t("Blog.SmallDividedCard.smalldctitle1")}
                smalldcdesc={t("Blog.SmallDividedCard.smalldcdesc1")}
            />
            </Link>
            <Link to='/Blog2' className='Link_divided__card'>
            <SmallDividedCard 
                smalldcimg="./images/134.png"
                smalldctitle={t("Blog.SmallDividedCard.smalldctitle2")}
                smalldcdesc={t("Blog.SmallDividedCard.smalldcdesc2")}
            />
            </Link>
            <Link to='/Blog3' className='Link_divided__card'>
            <SmallDividedCard 
                smalldcimg="./images/clica.jpg"
                smalldctitle={t("Blog.SmallDividedCard.smalldctitle3")}
                smalldcdesc={t("Blog.SmallDividedCard.smalldcdesc3")}
            />
            </Link>
            </div>
           
        </div>
    </div>
  )
}

export default BDCSection1
