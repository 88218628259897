import React from 'react';
import '../css/SplitSolutionImgShortInversed.css'

function SplitSolutionImgShortInversed(props) {
  return (
    <div>
    <div className='Split__solution__container__in' data-aos="zoom-out">
      <div className='Split__Section__img1__in'>
          <img alt='' src={props.imgback} />
      </div>
      <div className='Split__Section__img2__short__in'>
          <img alt='' src={props.imgfront} />
      </div>
    </div>
  </div>
  )
}

export default SplitSolutionImgShortInversed
