import React from 'react';
import '../css/FixedImgSection.css';
import 'aos/dist/aos.css';

function FixedImgSection(props) {
    const containerStyle = {
        background: `url(${props.src}) center/cover no-repeat fixed`,       
    };

    return (
        <div className='hero-Fixed__Container' style={containerStyle}>
            <div className='Fixed__Container__h'>
                <h1 className='Fixed__text' data-aos="fade-right" data-aos-delay="200">
                    {props.heroTITLEH1}
                </h1>
            </div>
            <div className='Fixed__Container__p'>
                <p className='Fixed__text' data-aos="fade-right" data-aos-delay="200">
                    {props.herotext}
                </p>
                <h3 className='Fixed__text' data-aos="fade-right" data-aos-delay="200">
                    {props.heroH1}
                </h3>
                <h3 className='Fixed__text1' data-aos="fade-right" data-aos-delay="200">
                    {props.heroH2}
                </h3>
            </div>
        </div>
    );
}

export default FixedImgSection;
