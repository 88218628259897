import React ,{useEffect}  from 'react';
import '../../Mini-components/css/ContactUsFooter.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";


function ContactUsFooter() {
    const [t] = useTranslation("global");
    useEffect(() => {
        Aos.init({ duration:3000});
      },[]);
  return (

    <div className='Contact__footer__container'>
        <div className='Contact__footer'>
            <div className='contact__maps' >
                <iframe data-aos="zoom-in"  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13753.918472269097!2d-7.641016835395685!3d33.565036729263596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xda62d349abcd0d1%3A0xe46f74df88502b51!2sL&#39;Oasis%2C%20Casablanca!5e0!3m2!1sfr!2sma!4v1696624639609!5m2!1sfr!2sma" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
            </div>
            <div className='contact__form' data-aos="fade-down">
                <form>
                    <input className='contact__form__input' type="text"  name="contact_name" id="contact_name" placeholder={t("Mahham.ContactUs.ContactUsFooter.contact_name")} required="" />
                    <input className='contact__form__input' type="email"  name="contact_email" id="contact_email" placeholder={t("Mahham.ContactUs.ContactUsFooter.contact_email")} required="" />
                    <textarea  className='contact__text' name="contact_text" id="contact_text"placeholder={t("Mahham.ContactUs.ContactUsFooter.contact_text")} required="" />
                    <button className='contact__button' type="submit"   buttonSize={'btn--medium'}>{t("Mahham.ContactUs.ContactUsFooter.contact__button")}</button> 
                </form>
            </div>
        </div>
    </div>
  )
}

export default ContactUsFooter
