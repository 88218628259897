import React , { useEffect } from 'react'
import '../../Mini-components/css/CardItemActualite.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

function CardItemActualite(props) {
    const box2actualite = {
        backgroundImage: `url('${props.backimg}')`,
      };
      useEffect(() => {
        Aos.init({ duration:500});
      },[]);
      
  return (
    <div className='container__card__actualite'  >
      <>
       <div className="box__actualite" id="box1" style={box2actualite} >
            <div className="box2actualite">
                <p className='card__desc__actualite' > {props.desc} </p>
            </div>
        </div>
      </>
    </div>
  )
}

export default CardItemActualite
