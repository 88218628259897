import React from 'react'
import '../component/Animation.css'

function Animation() {
  return (
    <div>
        <div class="stacked-images1">
            <img src="../images/12.png" alt='' className="image-11"/>
            <img src="../images/12.png" alt='' className="image-21"/>
            <img src="../images/12.png" alt='' className="image-31"/>
            <img src="../images/12.png" alt='' className="image-41"/>
            <img src="../images/12.png" alt='' className="image-51"/>
            <img src="../images/12.png" alt='' className="image-61"/>
            <img src="../images/12.png" alt='' className="image-71"/>
        </div>
    </div>
  )
}

export default Animation
