import React from 'react'
import ListItem from '../Mini-components/js/ListItem'
import { Link } from 'react-router-dom'
import '../css/List.css'
import { useTranslation } from "react-i18next";


function List(props) {
    const [t] = useTranslation("global");
  return (
    <>
    <div  className="list__item__cont">
        <Link className='Link_divided__card'>
            <ListItem
                aosdel='100'
                listicon='fa-brands fa-hubspot  green-clr-bg'
                listtitre={t("List.ListItem1.listtitre")}
                listdesc={t("List.ListItem1.listdesc")}
            />
        </Link>
        <Link className='Link_divided__card'>
            <ListItem
                aosdel='200'
                listicon='fa-solid fa-chart-simple green-clr-bg'
                listtitre={t("List.ListItem2.listtitre")}
                listdesc={t("List.ListItem2.listdesc")}
            />
        </Link>
        <Link className='Link_divided__card'>
            <ListItem
                aosdel='300'
                listicon='fa-brands fa-digital-ocean green-clr-bg'
                listtitre={t("List.ListItem3.listtitre")}
                listdesc={t("List.ListItem3.listdesc")}
            />
        </Link>
        <Link className='Link_divided__card'>
            <ListItem
                aosdel='400'
                listicon='fa-brands fa-digital-ocean green-clr-bg'
                listtitre={t("List.ListItem4.listtitre")}
                listdesc={t("List.ListItem4.listdesc")}
            />
        </Link>
    </div>
    
    </>
  )
}

export default List
