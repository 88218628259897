import React from 'react';
import '../css/SecondeSection.css';
import 'aos/dist/aos.css';

function SecondeSection(props) {
  return (
    <div className='seconde-container'>
        <div className='Image_SC'>
            <img src={props.src}  alt='' className="image-back"></img>
            <img src={props.logoV} alt='' className="imageV"></img>
            <p data-aos="fade-right" data-aos-delay="200"  className='seconde__container__text'>{props.textP}</p>    
            <h1 data-aos-delay="200" data-aos="fade-right" className='seconde__container__text'>{props.h1}</h1>      
        </div>          
    </div> 
  )
}

export default SecondeSection
