import React, { useEffect } from 'react'
import '../../Mini-components/css/SplitSectionText.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

function SplitSectionText(props) {
  useEffect(() => {
    Aos.init({ duration:3000});
  },[]);
  return (
    <div>
        <div className='Split__text_container'data-aos="fade-up">
            <h3 className='Split__title'>{props.splittitlte}</h3>
            <p className='Split__desc'>{props.splitdesc}</p>
        </div>
    </div>
  )
}


export default SplitSectionText
