import React from 'react';
import '../css/HeroSection.css';
import Canva_Particles from '../Mini-components/js/Canva_Particles';
import 'aos/dist/aos.css';
import logoV from "../../assets/LogoV.png";

function HeroSection(props) {
  return (
    <div className='container'>
        
        <div className='hero-container '>
          <Canva_Particles  />
          <img src={props.src}  alt='' className="image-back"></img>
          <img src={logoV} alt='' className="image-log"></img>
          <p data-aos="fade-right" data-aos-delay="200" >
            {props.herotext}
          </p>
          <h1 data-aos="fade-right" data-aos-delay="200" >{props.heroH1}</h1>    
        </div>     
    </div>
  )
}

export default HeroSection
