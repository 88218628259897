import React , { useEffect } from 'react';
import CardItem from '../Mini-components/js/CardItem';
import '../css/Cards.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import TextSection from '../../components/js/TextSection';
import { useTranslation } from "react-i18next";


function Cards(props) {
  const [t] = useTranslation("global");
    useEffect(() => {
        Aos.init({ duration:3000});
      },[]);
     
  return (
    <div className='cards'>
      <TextSection 
        cardstitle="Morocco"
        cardsdesc="A hub of attractiveness and competitiveness."
       />
      <div className="cards__container__main">
        <div className='cards__wrapper'>
            <div data-aos="fade-down" data-aos-delay="800" >
                <CardItem  
                    desc={t("Cards.CardItem1.desc")}
                    text={t("Cards.CardItem1.text")}
                    backimg='../images/MA1.jpg'
                />
            </div>
            <div data-aos="zoom-out" data-aos-delay="1000" >
                 <CardItem            
                     desc={t("Cards.CardItem2.desc")}
                     text={t("Cards.CardItem2.text")}
                    backimg='../images/MA2.jpg'
                />
            </div>
            <div data-aos="fade-up" data-aos-delay="800" >
                 <CardItem  
                     desc={t("Cards.CardItem3.desc")}
                     text={t("Cards.CardItem3.text")}
                    backimg='../images/MA6.jpg'
                />
            </div>
        </div>
      </div>
    </div>
  )
}

export default Cards
