import React from 'react'
import Navbar from '../../components/js/Navbar'
import Footer from '../../components/js/Footer'
import HeroSection from '../../components/js/HeroSection'
import { Button } from '../../components/js/Button';
import LoginSection from '../../components/js/LoginSection';
import Contactt from '../../components/js/Contact';
import DividedCards from '../../components/js/DividedCards';
import TextSection from '../../components/js/TextSection';
import { useTranslation } from "react-i18next";
import hero from "../../assets/cccc.png";


function Contact() {
  const [t] = useTranslation("global");
  const topFunction = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div>
      <Navbar/>
      <HeroSection src={hero}  heroH1={t("Contact.HeroSection.heroH1")}/> 
      <Contactt 
        src="./images/117.png"
        text="CONTACTEZ NOUS"
      />
      <TextSection 
      cardstitle={t("Contact.TextSection.cardstitle")}
      cardsdesc={t("Contact.TextSection.cardsdesc")}
     
      />
       <DividedCards
         divcardim1="./images/133.png"
         dctitle1={t("Contact.DividedCards.dctitle1")}
         divcardim2="./images/134.png"
         dctitle2={t("Contact.DividedCards.dctitle2")}
         divcardim3="./images/soufian1.jpg"
         dctitle3={t("Contact.DividedCards.dctitle3")}
        />      
      <Footer/>
      <Button onClick={topFunction} id="movetop" title="Go to top"  buttonStyle= 'btn--primary' buttonSize={'btn--ScrollUp'}><i className="fas fa-level-up-alt"></i></Button>
    </div>
  )
}

export default Contact
