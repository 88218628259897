import React from 'react'
import SplitSectionText from '../Mini-components/js/SplitSectionText'
import SplitSolutionImg from '../Mini-components/js/SplitSolutionImg'
import '../css/SplitSolution.css'
import SplitSectionSupp from '../Mini-components/js/SplitSectionSupp'
import SplitSolutionImgshort from '../Mini-components/js/SplitSolutionImgshort'


function SplitSolutionShort(props) {
  return (
    <div className='split__solution'>
      <div className='split__solution__container'>
        <SplitSolutionImgshort 
            imgback={props.split}
            imgfront={props.split1}
        />
        <SplitSectionSupp
            splittitlte={props.splitbigtitle}
            splitdesc={props.splitbigdesc}
        />
      </div>
    </div>
  )
}

export default SplitSolutionShort
