import React from 'react';
import '../css/BigDividedCard.css';
import BigDividedCardText from './BigDividedCardText';
import { useTranslation } from "react-i18next";


function BigDividedCard() {
  const [t] = useTranslation("global");
  return (
    <div>
      <div className='big__dividedcard__container'>
        <img alt='' src="./images/133.png"/>
        <div className='big__dividedcard__text'>
            <BigDividedCardText 
                titleBDC={t("Blog.blog1.titleBDC1")}
                descBDC={t("Blog.blog1.descBDC1")}
                />
            <BigDividedCardText 
                titleBDC={t("Blog.blog1.titleBDC2")}
                descBDC={t("Blog.blog1.descBDC2")}
            />
            <BigDividedCardText 
               titleBDC={t("Blog.blog1.titleBDC3")}
               descBDC={t("Blog.blog1.descBDC3")}
            />
            <BigDividedCardText 
                 titleBDC={t("Blog.blog1.titleBDC4")}
                 descBDC={t("Blog.blog1.descBDC4")}
            />
            <BigDividedCardText 
                 titleBDC={t("Blog.blog1.titleBDC5")}
                 descBDC={t("Blog.blog1.descBDC5")}
            />
        </div>
      </div>
    </div>
  )
}

export default BigDividedCard
