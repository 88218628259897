import React from "react";
import Animation from "./Animation";

function SliderContent({ activeIndex, sliderImage }) {
  return (
    <section>
      {sliderImage.map((slide, index) => (
        <div
          key={index}
          className={index === activeIndex ? "slides active" : "inactive"}
        >
          <img className="slide-image" src={slide.urls} alt="" />
          <div class="slider_number-container" >
            <div class="number-box">
                <span className={slide.slide_number1} style={{margin:'10px'}} >1</span> 
                <hr/>            
                <span className={slide.slide_number2}  style={{margin:'10px'}}>2</span>  
                <hr/>                    
                <span className={slide.slide_number3} style={{margin:'10px'}}>3</span>
                <hr/>
                <span className={slide.slide_number4} style={{margin:'10px'}}>4</span>
            </div>
          </div>
          <img className="image__V" src={slide.urls__V} alt="" />
          <p className="slide-title" data-aos="fade-right" data-aos-delay="200">{slide.title} </p>
          <h3 className="slide-text" data-aos="fade-right" data-aos-delay="200">{slide.description}</h3>
          <div className="container__button">
            <button  class="gradient-button" >
                <h5>Get Started</h5>
            </button>
          </div>
          <Animation/>
          
        </div>
      ))}
    </section>
  );
}

export default SliderContent;
