import React, { useEffect } from 'react';
import '../../Mini-components/css/SplitSectionImgSmall.css'
import Aos from 'aos';
import 'aos/dist/aos.css';

function SplitSectionImgSmall(props) {
  useEffect(() => {
    Aos.init({ duration:3000});
  },[]);
  return (
    <div>
    <div className='Split_img_container_small' data-aos="zoom-in">
      <img alt='' src={props.srcsplit}/>
    </div>
  </div>
  )
}

export default SplitSectionImgSmall
