import React , { useEffect } from 'react';
import '../../Mini-components/css/SplitTextSectionColor.css';
import { Button } from '../../js/Button';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

function SplitTextSectionColor(props) {
  const [t] = useTranslation("global");

  useEffect(() => {
    Aos.init({ duration:3000});
  },[]);
  return (
    <div>
        <div className='Split__text__color'>
            <h3 className='Split__title__color' data-aos="fade-down">{props.splittitlte}</h3>
            <p className='Split__desc__color' data-aos="fade-down">{props.splitdesc}</p>
            <div className='split__but' data-aos="flip-left">
              <Button buttonStyle={'btn--primary'} buttonSize={'btn--LearnMore'} buttonPath={'/AboutUs'} >{t("Mahham.SplitSectionTC.Button")}</Button>
            </div>
        </div>
    </div>
  )
}

export default SplitTextSectionColor
