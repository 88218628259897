import React ,{useEffect} from 'react';
import '../../Mini-components/css/ContactUsHeader.css';
import { Link } from 'react-router-dom';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";

function ContactUsHeader() {
    const [t] = useTranslation("global");
    useEffect(() => {
        Aos.init({ duration:3000});
      },[]);
  return (
    <div>
        <div className='Contact__header'>
            <div className="Contact__big__title">
                <h4 className="Contact__title" data-aos="fade-right" >{t("Mahham.ContactUs.ContactUsHeader.Contact__title")}</h4>
                <div className="Contact__text">
                    <Link><p className='p__home' data-aos="fade-right">{t("Mahham.ContactUs.ContactUsHeader.p__home")}</p></Link> 
                    <p  className='p__home__i' data-aos="fade-right"> <i className="fas fa-angle-right mx-2" /></p> 
                    <p className='p__contact' data-aos="fade-right">{t("Mahham.ContactUs.ContactUsHeader.p__contact")}</p> 
                </div>
            </div>
            <div className="Contact__icons__list">
                <div className='Contact__icons__container'>
                    <div className="Contact__single__infos" data-aos="flip-left">
                        <div  className="Contact__infos__icon"> 
                            <i  className="fas fa-map-marker-alt green-clr-bg" />
                        </div>
                        <div  className="Contact__text__infos">
                            <h3  className="Contact__title__infos" >{t("Mahham.ContactUs.ContactUsHeader.Contact__title__infos1")}</h3>
                            <p className="Contact__desc__infos">{t("Mahham.ContactUs.ContactUsHeader.Contact__desc__infos1")}</p>
                        </div>
                    </div>
                    <div className="Contact__single__infos" data-aos="flip-left">
                        <div  className="Contact__infos__icon"> 
                            <i  className="fas fa-clock green-clr-bg" />
                        </div>
                        
                        <div  className="Contact__text__infos">
                            <h3 className="Contact__title__infos">{t("Mahham.ContactUs.ContactUsHeader.Contact__title__infos2")}</h3>
                            <p className="Contact__desc__infos" >{t("Mahham.ContactUs.ContactUsHeader.Contact__desc__infos2")}</p>                        
                        </div>
                    </div>
                    <div className="Contact__single__infos" data-aos="flip-left">
                        <div  className="Contact__infos__icon"> 
                            <i  className="fas fa-envelope-open-text green-clr-bg" />
                        </div>
                        <div className="Contact__text__infos">
                            <h3 className="Contact__title__infos">{t("Mahham.ContactUs.ContactUsHeader.Contact__title__infos3")}</h3>
                            <Link> <p className="Contact__desc__infos">{t("Mahham.ContactUs.ContactUsHeader.Contact__desc__infos3")}</p></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
  )
}

export default ContactUsHeader
