
import React, { useState } from 'react';
import '../css/SecondeSectionVideo.css';
import 'aos/dist/aos.css';
import { Link } from 'react-router-dom';

function SecondeSectionVideo(props) {
  const [isDialogActive, setIsDialogActive] = useState(false);

  const toggle = () => {
    setIsDialogActive(!isDialogActive);

    const video = document.querySelector("video");
    if (video) {
      if (isDialogActive) {
        video.pause();
        video.currentTime = 0;
      }
    }
  };
  return (
    <div className='seconde-containerVideo'>
      <div className='Image__SC__video'>    
          <img src={props.src}  alt='' className="image_back_video"/>   
          <h1 data-aos-delay="200" data-aos="fade-right" >{props.h1}</h1>
          <Link onClick={toggle} className='Link_divided__card'>
          <div className="video_play_icon">
             <span   className="fa fa-play"></span>
          </div>
          </Link>   
        {isDialogActive && (
          <div onClick={toggle} id="small-dialog" className="zoom-anim-dialog active">
            <video src="./images/gggtest.mp4" allowFullScreen autoPlay />
          </div>
        )}
      </div>
    </div>
  );
}

export default SecondeSectionVideo;
