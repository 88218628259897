import React , { useEffect }from 'react'
import '../../Mini-components/css/SplitSectionImg.css'
import Aos from 'aos';
import 'aos/dist/aos.css';

function SplitSectionImg(props) {
  useEffect(() => {
    Aos.init({ duration:3000});
  },[]);
  return (
    <div>
      <div className='Split_img_container' data-aos="zoom-out">
        <img alt='' src={props.srcsplit}/>
      </div>
    </div>
  )
}


export default SplitSectionImg
