import React from 'react'
import ContactUsHeader from '../Mini-components/js/ContactUsHeader'
import ContactUsFooter from '../Mini-components/js/ContactUsFooter'
import '../css/ContactUs.css'

function ContactUs() {
  return (
    <div className='Contact__container'>
      <ContactUsHeader />
      <ContactUsFooter />
    </div>
  )
}

export default ContactUs
