import React, { useEffect } from 'react'
import '../../Mini-components/css/SplitSectionVideo.css'
import Aos from 'aos';
import 'aos/dist/aos.css';


function SplitSectionVideo(props) {
  useEffect(() => {
    Aos.init({ duration:3000});
  },[]);
  return (
    <div>
      <div className='Split_video_container' >
        <video className='video__rad'src={props.srcsplitvideo} autoPlay muted loop />
      </div>
    </div>
  )
}

export default SplitSectionVideo
