import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MAHAAM from './Pages/js/MAHAAM';
import SecondeSectionVideo from './components/js/SecondeSectionVideo';
import AboutUs from './Pages/js/AboutUs';
import Solution from './Pages/js/Solution';
import Actualite from './Pages/js/Actualite';
import Contact from './Pages/js/Contact';
import Connexion from './Pages/js/Connexion';
import Blog1 from './Pages/js/Blog1';
import Blog2 from './Pages/js/Blog2';
import Blog3 from './Pages/js/Blog3';
import global_en from './Translations/english/global.json'
import global_fr from './Translations/french/global.json'
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';

i18next.init({
  interpolation :{escapeValue:true},
  lng:"english",
  resources:{
    english:{
      global:global_en,
    },
    french:{
      global:global_fr,
    }
  }
})

ReactDOM.render(
  <Router>
    <I18nextProvider i18n={i18next}> 
      <Routes>
        <Route path='/' element={<MAHAAM />} />
        <Route path="/video" element={<SecondeSectionVideo />} />
        <Route path='/AboutUs' element={<AboutUs />} />
        <Route path='/Solution' element={<Solution />} />
        <Route path='/Actualite' element={<Actualite />} />
        <Route path='/Contact' element={<Contact />} />
        <Route path='/Blog1' element={<Blog1 />} />
        <Route path='/Blog2' element={<Blog2 />} />
        <Route path='/Blog3' element={<Blog3 />} />
        <Route path='/Connexion' element={<Connexion />} />
      </Routes>
    </I18nextProvider>
  </Router>,
  document.getElementById('root')
);
