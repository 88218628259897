import React from 'react'
import Navbar from '../../components/js/Navbar'
import Footer from '../../components/js/Footer'
import SecondeSection from '../../components/js/SecondeSection'
import { Button } from '../../components/js/Button'
import ListSolution from '../../components/js/ListSolution'
import TextSection from '../../components/js/TextSection';
import IconSection from '../../components/js/IconSection'
import SplitVideoEco from '../../components/js/SplitVideoEco'
import SupImgSectionLeft from '../../components/js/SupImgSectionLeft'
import SupImgSectionRight from '../../components/js/SupImgSectionRight'
import HeroSection from '../../components/js/HeroSection'
import { useTranslation } from "react-i18next";
import FaqSolution from '../../components/js/FaqSolution'
import SplitSolution from '../../components/js/SplitSolution'
import SplitSolutionShort from '../../components/js/SplitSolutionShort'
import SplitSolutionShortInversed from '../../components/js/SplitSolutionShortInversed'
import hero from "../../assets/f1.png";

function Solution() {
  const [t] = useTranslation("global");

  const topFunction = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div>
      <Navbar/>          
      <HeroSection src={hero} herotext='MAHAAM' heroH1={t("Solution.HeroSection.heroH1")}/> 
      <div className='mahaam'>
      <ListSolution 
        list__solution_icon1="fa-solid fa-solar-panel green-clr-bg"
        list__solution_titre1={t("Solution.ListSolution.list__solution_titre1")}
        list__solution_desc1={t("Solution.ListSolution.list__solution_desc1")}
        list__solution_icon2="fa-solid fa-arrow-up-right-dots green-clr-bg"
        list__solution_titre2={t("Solution.ListSolution.list__solution_titre2")}
        list__solution_desc2={t("Solution.ListSolution.list__solution_desc2")}
        list__solution_icon3="fa-solid fa-gauge-simple green-clr-bg"
        list__solution_titre3={t("Solution.ListSolution.list__solution_titre3")}
        list__solution_desc3={t("Solution.ListSolution.list__solution_desc3")}
      />
     
      <SplitSolution
        splitbigtitle={t("Solution.SupImgSectionRight.splitbigtitle")}
        splitbigdesc={t("Solution.SupImgSectionRight.splitbigdesc")}
        split="./images/60.png"
        split1="./images/t6.jpg"
        />
      <SecondeSection src='../images/1000.png' logoV='./images/logoV.png' textP={t("Solution.SecondeSection.textP")} h1={t("Solution.SecondeSection.h1")}  /> 

       <SplitSolutionShortInversed
      splitbigtitle={t("Solution.SupImgSectionLeft.splitbigtitle")}
      splitbigdesc={t("Solution.SupImgSectionLeft.splitbigdesc")}
      split="./images/136.png"
      split1="./images/Solution2.png"
        />
       <SplitSolutionShort
         splitbigtitle={t("Solution.SupImgSectionRight2.splitbigtitle")}
         splitbigdesc={t("Solution.SupImgSectionRight2.splitbigdesc")}
         split="./images/bbb.jpg"
         split1="./images/Solution1.png"
        />

      <TextSection 
        cardstitle={t("Solution.TextSection.cardstitle")}
        cardsdesc={t("Solution.TextSection.cardsdesc")}
      />
      <SplitVideoEco 
        splitbigtitle={t("Solution.SplitVideoEco.splitbigtitle")}
        splitbigdesc={t("Solution.SplitVideoEco.splitbigdesc")}
        splitV="./images/solution.mp4"
      />
      <TextSection 
        cardstitle={t("Solution.TextSection2.cardstitle")}
        cardsdesc={t("Solution.TextSection2.cardsdesc")}
      />
      <IconSection />
      <FaqSolution
        splitfaqvideo="./images/solution.mp4"
      />
      <SecondeSection src='./images/pppp.jpg' logoV='./images/logoV.png' textP='MAHAAM' h1={t("Solution.SecondeSection2.h1")}  />   

      <Footer/>
      <Button onClick={topFunction} id="movetop" title="Go to top"  buttonStyle= 'btn--primary' buttonSize={'btn--ScrollUp'}><i className="fas fa-level-up-alt"></i></Button>
      </div>
    </div>
  )
}

export default Solution
