import React , { useEffect } from 'react';
import '../../Mini-components/css/ListItem.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

function ListItem(props) {
    useEffect(() => {
        Aos.init({ duration:3000});
      },[]);
      
  return (
    <div>
        <>
        <div data-aos="fade-down" data-aos-delay={props.aosdel}  className='List__container'>
            <div className='List__icon'>
                <i className={props.listicon} ></i>
            </div>
            <div className='List__text'>
                <h4>{props.listtitre}</h4>
                <p >{props.listdesc}</p>   
            </div>
        </div>
        </>
    </div>
  )
}

export default ListItem
