import React from 'react'
import HeroSection from '../../components/js/HeroSection'
import Navbar from '../../components/js/Navbar'
import Footer from '../../components/js/Footer'
import BDCSection1 from '../../components/js/BDCSection1'
import { Button } from '../../components/js/Button';
import { useTranslation } from "react-i18next";
import '../../Pages/css/MAHAAM.css';
import hero from "../../assets/act2.png";

function Blog2() {
  const [t] = useTranslation("global");

  const topFunction = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div>
    <Navbar />
    <HeroSection src={hero} herotext={t("Blog.HeroSection2.herotext")} heroH1={t("Blog.HeroSection2.heroH1")}/>
    <div className='mahaam'>
    <BDCSection1 />
    </div> 
    <Footer />
    <Button onClick={topFunction} id="movetop" title="Go to top"  buttonStyle= 'btn--primary' buttonSize={'btn--ScrollUp'}><i className="fas fa-level-up-alt"></i></Button>  
    </div>
  )
}

export default Blog2
