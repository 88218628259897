import React from 'react';
import '../css/SplitSolutionImgshort.css'

function SplitSolutionImgshort(props) {
  return (
    <div>
      <div className='Split__solution__container' data-aos="zoom-out">
        <div className='Split__Section__img1'>
            <img alt='' src={props.imgback} />
        </div>
        <div className='Split__Section__img2__short'>
            <img alt='' src={props.imgfront} />
        </div>
      </div>
    </div>
  )
}

export default SplitSolutionImgshort
