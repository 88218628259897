import React, { useState } from 'react';
import '../css/LoginSection.css';

function LoginSection(props) {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
  });

  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Basic form validation
    if (name === 'username') {
      setErrors({
        ...errors,
        username: value.length < 3 ? 'Username must be at least 3 characters long' : '',
      });
    } else if (name === 'password') {
      setErrors({
        ...errors,
        password: value.length < 6 ? 'Password must be at least 6 characters long' : '',
      });
    }
  };


  const containerStyle = {
    background: `url(${props.src}) center/cover `,
    height: '100vh',
  };

  return (
    <div className='container__login__contact' style={containerStyle}>

      <div className='hero-container__Login__contact '>
        <div className='title__login__contact'>
        <h1 className='text_login'>{props.text}</h1>
        </div>
        <form className='form__contact'>
          <label className='form__contact__label'>
            <input className='form__contact__input'
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              placeholder="Enter your username"
            />
          </label>
          {errors.username && <p className="error">{errors.username}</p>}

          <label className='form__contact__label'>
            <i class='bx bx-user'/>
            <input className='form__contact__input'
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Enter your password"
            />
          </label>
          {errors.password && <p className="error">{errors.password}</p>}

          <button className='form__contact__butt' type="submit">Login</button>
        </form>
      </div>
    </div>
  );
}

export default LoginSection;
