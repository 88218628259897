import React from 'react'
import Navbar from '../../components/js/Navbar'
import Footer from '../../components/js/Footer'
import HeroSection from '../../components/js/HeroSection'
import { Button } from '../../components/js/Button';
import DividedCards from '../../components/js/DividedCards'
import '../css/Actualite.css'
import SecondeSection from '../../components/js/SecondeSection';
import CardsActualite from '../../components/js/CardsActualite';
import FixedImgSection from '../../components/js/FixedImgSection';
import { useTranslation } from "react-i18next";
import logoV from "../../assets/LogoV.png";
import hero from "../../assets/act2.png";

function Actualite() {
  const [t] = useTranslation("global");
  const topFunction = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <>
    <Navbar/> 
    <HeroSection src={hero} herotext='MAHAAM' heroH1={t("News.HeroSection.heroH1")}/>  
    <div className='Actualite'>
        <DividedCards
         divcardim1="./images/133.png"
         dctitle1={t("News.DividedCards.dctitle1")}
         dcdesc1={t("News.DividedCards.dcdesc1")}
         divcardim2="./images/134.png"
         dctitle2={t("News.DividedCards.dctitle2")}
         dcdesc2={t("News.DividedCards.dcdesc2")}
         divcardim3="./images/soufian1.jpg"
         dctitle3={t("News.DividedCards.dctitle3")}
         dcdesc3={t("News.DividedCards.dcdesc3")}
        />  
    </div>
        <FixedImgSection src='../images/act1.png' heroTITLEH1={t("News.FixedImgSection.heroTITLEH1")} herotext={t("News.FixedImgSection.herotext")} heroH1='MAYA SOUHAIL' heroH2={t("News.FixedImgSection.heroH2")} />
    <div className='Actualite'>
        <CardsActualite />
        <SecondeSection 
        src='../images/pppp.jpg' logoV={logoV}  textP='MAHAAM' h1='A SMART AND GREEN WORLD'  
        />
        <Footer/>
        <Button onClick={topFunction} id="movetop" title="Go to top"  buttonStyle= 'btn--primary' buttonSize={'btn--ScrollUp'}><i className="fas fa-level-up-alt"></i></Button>
    </div>
    </>
  )
}

export default Actualite
