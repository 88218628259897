import React from 'react'
import '../css/TextSection.css'

function TextSection(props) {
  return (
    <div>
       <div className='cards__title' >
            <h1 data-aos="fade-up">{props.cardstitle} </h1>
            <p data-aos="fade-up"data-aos-delay="300">{props.cardsdesc}</p>
        </div>
    </div>
  )
}

export default TextSection
