import React, { useState } from 'react';
import '../css/SplitFaq.css';

function SplitFaq(props) {
  const [activeItems, setActiveItems] = useState(Array(props.totalItems).fill(false));

  const handleToggle = (index) => {
    const updatedActiveItems = [...activeItems];
    updatedActiveItems[index] = !updatedActiveItems[index];
    setActiveItems(updatedActiveItems);
  };

  return (
    <div>
        <div className='faq__header'>
            <h1>{props.faqheadertitle} </h1>
            <p>{props.faqheaderdesc}</p>
        </div>
      {Array.from({ length: props.totalItems }, (_, index) => (
        <div
          key={index}
          className={`faq__container ${activeItems[index] ? 'active' : ''}`}
          onClick={() => handleToggle(index)}
        >
          <div className='question'>
            <h3 className='faq__title'>{props.questions[index]}</h3>
            <svg width={'20'} height={'15'} viewBox='0 0 42 25'>
              <path
                d='M3 3L21 21L39 3'
                strokeWidth={'7'}
                strokeLinecap='round'
              />
            </svg>
          </div>
          <div className='answer'>
            <p className='faq__answer'>{props.answers[index]}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SplitFaq;
