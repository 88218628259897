import React from 'react'
import SplitSectionText from '../Mini-components/js/SplitSectionText'
import '../css/SplitSection.css'
import SplitSectionVideo from '../Mini-components/js/SplitSectionVideo'

function SplitSectionV(props) {
  return (
    <div className='split'>
      <div className='Split__container'>
        
        <SplitSectionText 
            splittitlte={props.splitbigtitle}
            splitdesc={props.splitbigdesc}
        />
        <SplitSectionVideo
            srcsplitvideo={props.splitV}
        />
      </div>
      </div>
  )
}

export default SplitSectionV
