import React from 'react'
import Navbar from '../../components/js/Navbar'
import LoginSection from '../../components/js/LoginSection';
import hero from "../../assets/117.png";


function Connexion() {
  return (
    <div>
        <Navbar/>
        <LoginSection
        src={hero}
        text="Login"
      /> 
    </div>
  )
}

export default Connexion
