import React, { useEffect } from 'react';
import '../../Mini-components/css/DividedCardItem.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

function DividedCardItem(props) {
    useEffect(() => {
        Aos.init({ duration:3000});
      },[]);
  return (
    <div data-aos={props.aosDCI} className='DC__container' >
      <div  className="divided__card">
        <img src={props.srcDCI} alt="" />
        <div className="divided__card__container">
            <h4>{props.titleDCI} </h4>
            <p>{props.descDCI} </p>
        </div>
    </div>
    </div>
  )
}

export default DividedCardItem
