import React , { useEffect } from 'react'
import { Link } from 'react-router-dom';
import '../../Mini-components/css/CardItem.css';
import Aos from 'aos';
import 'aos/dist/aos.css';

function CardItem(props) {
    const box2 = {
        backgroundImage: `url('${props.backimg}')`,
      };
      useEffect(() => {
        Aos.init({ duration:500});
      },[]);
      
  return (
    <div className='container__card'  >
      <>
       <div className="box" id="box1" style={box2} >
            <div className="box2">
                <p className='card__desc' > {props.desc} </p>
            </div>
            <div class="content">
                <p className='card__text' > {props.text} </p>
            </div>
        </div>
      </>
    </div>
  )
}

export default CardItem
