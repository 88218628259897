import React from 'react'
import SplitSectionText from '../Mini-components/js/SplitSectionText'
import '../css/SplitSection.css'
import SplitSectionImg from '../Mini-components/js/SplitSectionImg'

function SplitSectionInversed(props) {
  return (
    <div className='split'>
      <div className='Split__container'>
      <SplitSectionImg 
            srcsplit={props.splitinversed}
        />
        <SplitSectionText 
            splittitlte={props.inversedsplittitle}
            splitdesc={props.inversedsplitdesc}
        />
        
      </div>
      </div>
  )
}


export default SplitSectionInversed
