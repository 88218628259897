import React from 'react'
import '../css/List.css'

import { Link } from 'react-router-dom'
import ListSolutionItem from '../Mini-components/js/ListSolutionItem'

function ListSolution(props) {
  return (
    <div>
      <div  className="list__item__cont">
        <Link className='Link_divided__card'>
            <ListSolutionItem
                aosdel='100'
                listicon={props.list__solution_icon1}
                listtitre={props.list__solution_titre1}
                listdesc={props.list__solution_desc1}
            />
        </Link>
        <Link className='Link_divided__card'>
            <ListSolutionItem
                aosdel='200'
                listicon={props.list__solution_icon2}
                listtitre={props.list__solution_titre2}
                listdesc={props.list__solution_desc2}
            />
        </Link>
        <Link className='Link_divided__card'>
            <ListSolutionItem
                aosdel='300'
                listicon={props.list__solution_icon3}
                listtitre={props.list__solution_titre3}
                listdesc={props.list__solution_desc3}
            />
        </Link>
    </div>
    </div>
  )
}

export default ListSolution
