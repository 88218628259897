import React  from 'react'
import SplitSectionText from '../Mini-components/js/SplitSectionText'
import '../css/SplitSection.css'
import SplitSectionImg from '../Mini-components/js/SplitSectionImg'


function SplitSection(props) {

  return (
    <div className='split'>
      <div className='Split__container'>  
        <SplitSectionText 
            splittitlte={props.splitbigtitle}
            splitdesc={props.splitbigdesc}
        />
        <SplitSectionImg 
            srcsplit={props.split}
        />
      </div>
      </div>
  )
}

export default SplitSection
