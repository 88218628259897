import React,{useState,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../js/Button';
import '../css/Navbar.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import { useTranslation } from "react-i18next";
import Combo from "../Mini-components/js/combo"


function Navbar() {
    const [t,i18n] = useTranslation("global");
    const handleChangeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };
    


    useEffect(() => {
        Aos.init({ duration:2000});
      },[]);
    const [click,setClick] = useState(false);
    const [button,setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(true)
        } else{
            setButton(true);
        }
    }

    window.addEventListener('resize',showButton);

  return (
    <>
    <nav className="navbar">
        <div className="navbar-container">
           <Link to="/" className='navbar-logo'>
                <img data-aos="zoom-in" data-aos-once="true" alt='' src="./images/logoN.png" width="200px" height="40px"></img> 
            </Link> 
            <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
            </div>
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li data-aos="fade-right" data-aos-once="true" className='nav-item'>
                    <Link to='/' className='nav-links'  onClick={closeMobileMenu}>
                        <i className="fa-solid fa-house"></i>
                        &nbsp;MAHAAM 
                    </Link>
                </li>
                <li data-aos="fade-right" data-aos-once="true"
                    data-aos-delay="200"className='nav-item'>
                    <Link to='/AboutUs' className='nav-links' onClick={closeMobileMenu}>
                        {t("Navbar.aboutUs")}
                    </Link>
                </li>
                <li data-aos="fade-right" data-aos-once="true"
                    data-aos-delay="400"className='nav-item'>
                    <Link to='/Solution' className='nav-links' onClick={closeMobileMenu}>
                    {t("Navbar.solution")}
                    </Link>
                </li>
                <li data-aos="fade-right" data-aos-once="true"
                    data-aos-delay="600"className='nav-item'>
                    <Link to='/Actualite' className='nav-links' onClick={closeMobileMenu}>
                    {t("Navbar.news")}
                    </Link>
                </li>
                <li data-aos="fade-right" data-aos-once="true"
                    data-aos-delay="800"className='nav-item'>
                    <Link to='/Contact' className='nav-links' onClick={closeMobileMenu}>
                    {t("Navbar.contact")}
                    </Link>
                </li>
                <li  data-aos="fade-right"
                    data-aos-delay="1000" data-aos-once="true" className='nav-item'>
                    {button &&  <Link to='/Connexion' className='nav-links'   buttonStyle= 'btn--outline' buttonSize={'btn--connexion'}><i  class="fa-solid fa-globe fa-spin"></i>&nbsp;{t("Navbar.connection")}</Link>}
                </li>
                <li className='nav-item'>
                    <Combo/>
                </li>
                
                
            </ul>
        </div>
    </nav>
    </>
  )
}

export default Navbar
