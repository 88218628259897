import First from "../assets/1000.png"
import Second from "../assets/first.png";
import Third from "../assets/66.png";
import Fourth from "../assets/69.png";
import logoV from "../assets/LogoV.png";


export default [
  {
    urls__V:logoV ,
    slide_number1:"slide_number1",
    title: "MAHAAM",
    description: "AI FOR DECARBONATION",
    urls: First,
  },
  {
    urls__V:logoV ,
     slide_number2:"slide_number2",
    title: "MAHAAM",
    description: "DATA-DRIVEN SUSTAINABILITY",
    urls: Second,
  },
  {
    urls__V:logoV ,
    slide_number3:"slide_number3",
    title: "MAHAAM",
    description: "GREEN TRANSFORMATION",
    urls: Third,
  },
  {
    urls__V:logoV ,
    slide_number4:"slide_number4",
    title: "MAHAAM",
    description: "SMART ENERGY, SMART FUTURE",
    urls: Fourth,
  },
  
];
